import axios from "axios";

import { Initialize } from "./Interceptor";

Initialize();

export const getAuthorization = () => `Bearer ${localStorage.getItem("token")}`;

export const getHeader = (type) => {
  const headers = {
    "Content-Type": type ? type : "application/json; charset=utf-8",
    // 'Authorization': getAuthorization()
  };
  return { headers };
};


// Post
export async function post(url, data, headers) {
  const result = await axios.post(url, data, headers ? { headers } : getHeader())
  if (result && result.code) {
    if (result.code === "ERR_NETWORK") {
      return undefined
    }
  }

  if (result.status === 200) {
    if (result.data.data) {
      return result.data.data
    } else if (result.data.list) {
      return result.data.list
    }
    return result.data
  } else if (result.response) {
    return result.response
  }
  return result
}

export async function post2(url, data, headers) {
  const result = await axios.post(url, data, headers ? { headers } : getHeader())
  if (result && result.code) {
    if (result.code === "ERR_NETWORK") {
      return undefined
    }
  }
  if (result.data) {
    return result
  } else if (result.response) {
    return result.response
  }
  return result
}

// Get
export async function get(url, headers) {
  const result = await axios.get(url, headers ? { headers } : getHeader());
  if (result && result.code) {
    if (result.code === "ERR_NETWORK") {
      return {}
    }
  }
  if (result.status === 200) {
    if (result.data.data) {
      return result.data.data
    } else if (result.data.list) {
      return result.data.list
    }
    return result.data
  } else if (result.response) {
    return result.response
  }
  return result
}

// Put
export async function put(url, data, headers) {
  const response = await axios.put(url, data, headers ? { headers } : getHeader());

  if (response && response.data && response.status === 200) {
    return response.data;
  }
  return response;
}

// Delete
export async function Delete(url, headers) {
  const response = await axios.delete(url, headers ? { headers } : getHeader());

  if (response && response.data && response.status === 200) {
    return response.data;
  }

  return response;
}

export async function upload(url, data) {
  try {
    const response = await axios.post(url, data, getHeader('multipart/form-data'))
    if (response && response.data && response.status === 200) {
      return response.data.data;
    } else if (response.status === 201) {
      return true;
    }
  } catch (err) {

  }
}