import React from "react";

export default function InputGroup({ className, enabled = true, label, name, value, type, onChange, placeHolder, required }) {

    return (
        <div className={className + " edc-input-group mb-1"} >
            <label className="form-label w-75">{label}</label>
            <div className="d-flex flex-column">
                <input className="form-control text-input w-auto" disabled={!enabled} name={name} onChange={onChange} type={type} value={value} placeholder={placeHolder} required={required} />
            </div>
        </div>
    );
}
