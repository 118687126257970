import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
// import Login from "./pages/Login";
import Home from "./pages/home";



function App() {

  return (
    <HashRouter>
      <Routes>
        <Route element={<Home />} path="/" />
        {/* <Route
          element={

            <Dashboard />
          }
          path="/t/:tenantId/l/:locationId"
        /> */}
        {/* <Route
          element={
            <RequireAuth>
              {" "}
              <Dashboard />{" "}
            </RequireAuth>
          }
          path="/t/:tenantId/l/:locationId"
        /> */}
      </Routes>
    </HashRouter>
  );
}

export default App;
