import { useState } from 'react'
import { postAddress, postBusiness, postLocation, postSaveServiceRequestStatus, postUser, postUserLocation } from '../service/super-admin-services'
import { getUploadApiUrl } from '../service/ApiUrls'
import { upload } from '../service/Service'

export default function useHome() {

    const [files, setFiles] = useState([])
    const [section, setSection] = useState("")
    const [data, setData] = useState(
        {
            "addressLine1": "",
            "addressLine2": "",
            "city": "",
            "state": "",
            "pinCode": "",
            "country": "",
            "type": "OFFICE"
        }
    )
    const [business, setBusiness] = useState(
        {
            "name": "",
            "description": "",
            "addressId": ""
        }
    )
    const [location, setLocation] = useState(
        {
            "name": "",
            "description": "",
            "tenantId": "",
            "addressId": ""
        }
    )
    const [user, setUser] = useState(
        {
            "firstName": "",
            "lastName": "",
            "email": "",
            "token": "",
            "userType": "ADMIN"
        }
    )
    const [userLocation, setUserLocation] = useState(
        {
            "tenantId": "",
            "locationId": "",
            "userId": ""
        }
    )
    const [status, setStatus] = useState(
        {
            "tenantId": "",
            "locationId": "",
            "position": 1,
            "displayName": "New",
            "name": "NEW"
        }
    )
    const [statusName, setStatusName] = useState(
        [{ "status": "NEW", "name": "New" }, { "status": "ASSIGNED", "name": "Assigned" }, { "status": "IN-PROGRESS", "name": "In Progress" }, { "status": "COMPLETED", "name": "Completed" }, { "status": "CLOSED", "name": "Closed" }]
    )

    const handleInputChange = (event) => {
        if (section === "one") {
            setData((state) => ({
                ...state, [event.target.name]: event.target.value
            }))
        }
        if (section === "two") {
            setBusiness((state) => ({
                ...state, [event.target.name]: event.target.value
            }))
        }
        if (section === "three") {
            if ((event.target.name === "name") || (event.target.name === "description")) {
                setLocation((state) => ({
                    ...state, [event.target.name]: event.target.value
                }))
            } else {
                setUser((state) => ({
                    ...state, [event.target.name]: event.target.value
                }))
            }
        }
    }
    const handleClickNext = async (event, value) => {
        event.preventDefault()
        if (section === "") {
            setSection("one")
        }
        if (section === "one") {
            const one = await postAddress(data)
            console.log(one.id)
            if ((one.id !== null) && (one.id !== undefined)) {
                business.addressId = one.id
                location.addressId = one.id
                setSection("two")
            } else {
                setSection("seven")
            }
        } else if (section === "two") {
            if (files && files.length > 0) {
                // data.imageUrl = await uploadFile()
                business.imageUrl = await uploadFile(files)
            }
            const two = await postBusiness(business)
            if ((two.id !== null) && (two.id !== undefined)) {
                location.tenantId = two.id
                userLocation.tenantId = two.id
                status.tenantId = two.id
                setSection("three")
            } else {
                setSection("seven")
            }
        } else if (section === "three") {
            const four = await postUser(user)
            userLocation.userId = four.id
            if (four && four.data && four.data.error && (four.data.error.detailsMessage === "User already exist")) {
                setSection("four")
            } else {
                const three = await postLocation(location.tenantId, location)
                userLocation.locationId = three.id
                status.locationId = three.id
                // eslint-disable-next-line
                const five = await postUserLocation(userLocation)
                for (let i = 0; status.position < 6; i++) {
                    const six = await postSaveServiceRequestStatus(status.tenantId, status.locationId, status)
                    if (six.messages === "Saved Successfully.") {
                        if (status.position < 5) {
                            console.log("inc", status.position)
                            status.position = status.position + 1
                            let list = i + 1
                            status.displayName = statusName[list].name
                            status.name = statusName[list].status
                        } else {
                            status.position = 1
                            status.displayName = "New"
                            status.name = "NEW"
                            break
                        }
                    }
                }
                setSection("five")
            }
        }
        else if (value === "home") {
            setSection("")
            setData(
                {
                    "addressLine1": "",
                    "addressLine2": "",
                    "city": "",
                    "state": "",
                    "pinCode": "",
                    "country": "",
                    "type": "OFFICE"
                }
            )
            setBusiness(
                {
                    "name": "",
                    "description": "",
                    "addressId": "",
                    "imageUrl": "",
                }
            )
            setLocation(
                {
                    "name": "",
                    "description": "",
                    "tenantId": "",
                    "addressId": ""
                }
            )
            setUser(
                {
                    "firstName": "",
                    "lastName": "",
                    "email": "",
                    "token": "",
                    "userType": "ADMIN"
                }
            )
            setUserLocation(
                {
                    "tenantId": "",
                    "locationId": "",
                    "userId": ""
                }
            )
            setStatus(
                {
                    "tenantId": "",
                    "locationId": "",
                    "position": 1,
                    "displayName": "New",
                    "name": "NEW"
                }
            )
        } else if (value === "more") {
            location.name = ""
            location.description = ""
            setUser(
                {
                    "firstName": "",
                    "lastName": "",
                    "email": "",
                    "token": "",
                    "userType": "ADMIN"
                }
            )
            setSection("three")

        } else if (value === "retry") {
            // setUserLocation(
            //     {
            //         "tenantId": "",
            //         "locationId": "",
            //         "userId": ""
            //     }
            // )
            setSection("three")
        }
    }


    const onFileSelect = (data) => {
        setFiles(data)
    }
    const uploadFile = async (files) => {
        let file = files[0].file
        const dataImage = {
            tenantId: "",
            locationId: "",
            userId: localStorage.getItem("userId"),
            file: file
        }
        let response = await upload(getUploadApiUrl(), dataImage)
        if (response) {
            return response.mediaUrl;
        }
    }

    return { section, setSection, data, setData, handleInputChange, handleClickNext, business, setBusiness, location, setLocation, user, setUser, setUserLocation, onFileSelect, files, setStatus, setStatusName }
}
