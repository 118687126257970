const HOST = "https://dev-api-concierge.gjglobalsoft.com";

export const BASE_AUTH_URL = `${HOST}/`;

const BASE_ATTACHMENT_URL = `${HOST}/v1/super-admin`;

const BASE_ATTACHMENT_URL_ADMIN = `${HOST}/v1/admin`;


const BASE_ATTACHMENT_IMAGE_URL = `${HOST}/v1/super-admin/attachment`;

export const postAddressApiUrl = () => {
  return `${BASE_ATTACHMENT_URL}/address`;
};

export const postBusinessApiUrl = () => {
  return `${BASE_ATTACHMENT_URL}/business`;
};

export const postLocationApiUrl = (tenantId) => {
  return `${BASE_ATTACHMENT_URL}/tenant/${tenantId}/location`;
};

export const postUserApiUrl = () => {
  return `${BASE_ATTACHMENT_URL}/user`;
};

export const postUserLocationApiUrl = () => {
  return `${BASE_ATTACHMENT_URL}/user-location`;
};

export const getUploadApiUrl = () => {
  return BASE_ATTACHMENT_IMAGE_URL;
};

export const postSaveServiceRequestStatusUrl = (tenantId, locationId) => {
  return `${BASE_ATTACHMENT_URL_ADMIN}/tenant/${tenantId}/location/${locationId}/service-request-status`;
}
// export const getUserExistApiUrl = (email) => {
//   return `${BASE_ATTACHMENT_URL}v1/admin/user/user-exist?email=${email}`;
// };

// export const getSaveDepartmentApiUrl = (tenantId, locationId) => {
//   return `${BASE_ATTACHMENT_URL}${tenantId}/location/${locationId}/department`;
// };