import React from "react";
import Form from "../components/containers/form";
import HDiv from "../components/containers/hdiv";
import VDiv from "../components/containers/vdiv";
import Button from "../components/inputs/button";
import InputGroup from "../components/inputs/input-group";
import useHome from "../hooks/useHome";
import Select from "../components/inputs/select";
import FileSelector from "../components/inputs/file-selector";

export default function Home() {
    const { section, handleInputChange, data, handleClickNext, business, location, user, onFileSelect } = useHome()
    return (
        <HDiv className="page-form-container">
            <VDiv className="page-form-banner">
                <VDiv className="sliders">
                    <VDiv className="slider slider1"></VDiv>
                </VDiv>
            </VDiv>
            <VDiv className="">
                {(section === "") &&
                    <Form
                        title="Welcome to GJ Concierge"
                        subTitle=""
                        showLogo
                    >
                        <form
                            onSubmit={(e) => { e.preventDefault(); handleClickNext(e); }}
                            onChange={(event) => handleInputChange(event)}
                            autoComplete="off"
                            className="container py-5"
                        >
                            <Button className="w-100" label={"Create Account"} type={"submit"} />
                        </form>
                    </Form>
                }
                {(section === "one") &&
                    <Form
                        title="Create Details Of Business"
                        subTitle=""
                    >
                        <form
                            onSubmit={(e) => { e.preventDefault(); handleClickNext(e); }}
                            onChange={(event) => handleInputChange(event)}
                            autoComplete="off"
                            className="container "
                        >
                            <InputGroup required label={"Address Line-1"} type={"text"} name={"addressLine1"} value={data.addressLine1} onChange={(event) => handleInputChange(event)} />
                            <InputGroup required label={"Address Line-2"} type={"text"} name={"addressLine2"} value={data.addressLine2} onChange={(event) => handleInputChange(event)} />
                            <InputGroup required label={"City"} type={"text"} name={"city"} value={data.city} onChange={(event) => handleInputChange(event)} />
                            <InputGroup required label={"State"} type={"text"} name={"state"} value={data.state} onChange={(event) => handleInputChange(event)} />
                            <InputGroup required label={"Pin Code"} type={"number"} name={"pinCode"} value={data.pinCode} onChange={(event) => handleInputChange(event)} />
                            <InputGroup required label={"Country"} type={"text"} name={"country"} value={data.country} onChange={(event) => handleInputChange(event)} />
                            <Select label="Type" onChange={(event) => handleInputChange(event)} name={"type"} value={data.type} />
                            <Button className="w-100 mt-4" label={"Next"} type={"submit"} />
                        </form>
                    </Form>
                }
                {(section === "two") &&
                    <Form
                        title="Create Business Name"
                        subTitle=""
                    >
                        <form
                            onSubmit={(e) => { e.preventDefault(); handleClickNext(e); }}
                            onChange={(event) => handleInputChange(event)}
                            autoComplete="off"
                            className="container"
                        >
                            <InputGroup required label={"Name"} type={"text"} name={"name"} value={business.name} onChange={(event) => handleInputChange(event)} />
                            <InputGroup required label={"Description"} type={"text"} name={"description"} value={business.description} onChange={(event) => handleInputChange(event)} />
                            <FileSelector
                                name="attachment"
                                label="Upload Logo"
                                // showProgress={uploadInProgress}
                                onFileSelect={onFileSelect}

                            />
                            <Button className="w-100 mt-4" label={"Next"} type={"submit"} />
                        </form>
                    </Form>
                }
                {(section === "three") &&
                    <Form
                        title="Create Branch And Admin"
                        subTitle=""
                    >
                        <form
                            onSubmit={(e) => { e.preventDefault(); handleClickNext(e); }}
                            onChange={(event) => handleInputChange(event)}
                            autoComplete="off"
                            className="container"
                        >
                            <InputGroup required label={"Branch Name"} type={"text"} name={"name"} value={location.name} onChange={(event) => handleInputChange(event)} />
                            <InputGroup required label={"Description"} type={"text"} name={"description"} value={location.description} onChange={(event) => handleInputChange(event)} />
                            <InputGroup required label={"First Name"} type={"text"} name={"firstName"} value={user.firstName} onChange={(event) => handleInputChange(event)} />
                            <InputGroup required label={"Last Name"} type={"text"} name={"lastName"} value={user.lastName} onChange={(event) => handleInputChange(event)} />
                            <InputGroup required label={"Email"} type={"email"} name={"email"} value={user.email} onChange={(event) => handleInputChange(event)} />
                            <InputGroup required label={"Password"} type={"text"} name={"token"} value={user.token} onChange={(event) => handleInputChange(event)} />
                            <Button className="w-100 mt-4" label={"Next"} type={"submit"} />
                        </form>
                    </Form>
                }
                {(section === "four") &&
                    <Form
                        classNameTitle="text-danger"
                        title="User already exist, Create with new email id"
                        subTitle=""
                    >
                        <form
                            onChange={(event) => handleInputChange(event)}
                            autoComplete="off"
                            className="container"
                        >
                            <VDiv className=" mt-5">
                                <Button className="mb-5 w-100" label={"Retry With New Email"} type={"submit"} onClick={(e) => handleClickNext(e, "retry")} />
                                <Button className="w-100" label={"Go Back To Home"} type={"submit"} onClick={(e) => handleClickNext(e, "home")} />
                            </VDiv>
                        </form>
                    </Form>
                }
                {(section === "five") &&
                    <Form
                        title="Thank You"
                        subTitle=""
                    >
                        <form
                            onChange={(event) => handleInputChange(event)}
                            autoComplete="off"
                            className="container"
                        >
                            <VDiv className=" mt-5">
                                <Button className="mb-5 w-100" label={"Create More Tenant"} type={"submit"} onClick={(e) => handleClickNext(e, "more")} />
                                <Button className="w-100" label={"Go Back To Home"} type={"submit"} onClick={(e) => handleClickNext(e, "home")} />
                            </VDiv>
                        </form>
                    </Form>
                }
                {(section === "seven") &&
                    <Form
                        title="Issue in creating Acoount"
                        subTitle="Please try to create new one"
                    >
                        <form
                            onChange={(event) => handleInputChange(event)}
                            autoComplete="off"
                            className="container"
                        >
                            <VDiv className=" mt-5">
                                {/* <Button className="mb-5 w-100" label={"Create More Tenant"} type={"submit"} onClick={(e) => handleClickNext(e, "more")} /> */}
                                <Button className="w-100" label={"Go Back To Home"} type={"submit"} onClick={(e) => handleClickNext(e, "home")} />
                            </VDiv>
                        </form>
                    </Form>
                }

            </VDiv>
        </HDiv>
    )
}
