import React from 'react'

export default function ({ value, className, label, onChange, name }) {
    return (
        <div className={className + " edc-input-group mb-1"} >
            <label className="form-label w-75">{label}</label>
            <select name={name} id="mySelect" value={value} className='form-control text-input ' onChange={onChange} >
                <option className='' value="OFFICE">OFFICE</option>
            </select>

        </div>
    )
}