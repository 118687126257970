import { postAddressApiUrl, postBusinessApiUrl, postLocationApiUrl, postSaveServiceRequestStatusUrl, postUserApiUrl, postUserLocationApiUrl } from "./ApiUrls";
import { post } from "./Service";


export const postAddress = async (data) => {
    const response = await post(postAddressApiUrl(), data);
    return response;
};

export const postBusiness = async (data) => {
    const response = await post(postBusinessApiUrl(), data);
    return response;
};

export const postLocation = async (tenantId, data) => {
    const response = await post(postLocationApiUrl(tenantId), data);
    return response;
};

export const postUser = async (data) => {
    const response = await post(postUserApiUrl(), data);
    return response;
};

export const postUserLocation = async (data) => {
    const response = await post(postUserLocationApiUrl(), data);
    return response;
};

export const postSaveServiceRequestStatus = async (tenantId, locationId, data) => {
    const response = await post(postSaveServiceRequestStatusUrl(tenantId, locationId), data);
    return response;
}
