import React from 'react'

import VDiv from './vdiv'
import Text from '../elements/texts/text'

export default function Form({ title, subTitle, children, className, showLogo, classNameTitle }) {
    return (
        <VDiv className={`form-container ${className ? className : ''}`}>
            <VDiv className="form">
                {showLogo ? <img className="form-logo mb-3" src="../assets/images/logo-rounded-alt.png" alt="Sree Gokulam Hospital" /> : null}
                <Text text={title} size="lg" className={`form-container mb-3 block ${classNameTitle ? classNameTitle : ''}`} />
                <Text text={subTitle} size="md" className="mb-3 block" />
                <VDiv className="row">
                    {children}
                </VDiv>
            </VDiv>
        </VDiv>
    )
}